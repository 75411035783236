<template>
    <div>
        自动登录失败，点击重新登录
    </div>
</template>

<script>
export default {
    name: "ErrorView"
}
</script>

<style scoped>

</style>